var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "orderTest-container" },
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: {
            model: _vm.form,
            inline: "",
            "label-position": "right",
            "label-suffix": ":",
            "label-width": "120px",
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "", prop: "" } },
            [
              _c(
                "el-select",
                {
                  attrs: {
                    multiple: "",
                    clearable: "",
                    placeholder: "退货方式",
                  },
                  model: {
                    value: _vm.form.refund_type,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "refund_type", $$v)
                    },
                    expression: "form.refund_type",
                  },
                },
                [
                  _c("el-option", { attrs: { label: "现金", value: "1" } }),
                  _c("el-option", { attrs: { label: "转存货", value: "2" } }),
                  _c("el-option", { attrs: { label: "抵欠款", value: "3" } }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "", prop: "staff_id" } },
            [
              _c("StaffSelect", {
                ref: "staffSelect",
                staticStyle: { width: "180px" },
                on: { "change-staff": _vm.changeStaff },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "", prop: "goods_type" } },
            [
              _c(
                "el-select",
                {
                  attrs: {
                    multiple: "",
                    clearable: "",
                    placeholder: "退货类型",
                  },
                  model: {
                    value: _vm.form.goods_type,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "goods_type", $$v)
                    },
                    expression: "form.goods_type",
                  },
                },
                [
                  _c("el-option", { attrs: { label: "退常品", value: "6" } }),
                  _c("el-option", { attrs: { label: "退赠品", value: "10" } }),
                  _c("el-option", { attrs: { label: "退尝品", value: "13" } }),
                  _c("el-option", {
                    attrs: { label: "预存退货", value: "14" },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "", prop: "" } },
            [
              _c("client-search", {
                ref: "clientSearch",
                attrs: { "popper-class": "selectDC" },
                on: { "select-val": _vm.setClientKeyword },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "", prop: "" } },
            [
              _c("goods-search", {
                ref: "goodsSearch",
                attrs: {
                  "is-table": false,
                  "popper-class": "select-idx selectDC",
                },
                on: {
                  "input-goods": _vm.setkeyword,
                  "select-search-goods": _vm.setkeyword,
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "", prop: "" } },
            [
              _c(
                "el-select",
                {
                  attrs: { clearable: "", placeholder: "时间类型" },
                  model: {
                    value: _vm.form.time_type,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "time_type", $$v)
                    },
                    expression: "form.time_type",
                  },
                },
                [
                  _c("el-option", { attrs: { label: "下单时间", value: "1" } }),
                  _c("el-option", { attrs: { label: "到货时间", value: "2" } }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "", prop: "" } },
            [
              _c("el-date-picker", {
                attrs: {
                  type: "daterange",
                  "range-separator": "-",
                  "value-format": "yyyy-MM-dd",
                  "start-placeholder": "开始日期",
                  "end-placeholder": "结束日期",
                },
                model: {
                  value: _vm.time,
                  callback: function ($$v) {
                    _vm.time = $$v
                  },
                  expression: "time",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "", prop: "" } },
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.handleQuery } },
                [_vm._v("查询")]
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "", prop: "" } },
            [
              _c("el-button", { on: { click: _vm.handleExport } }, [
                _vm._v("导出"),
              ]),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "", prop: "" } },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.handleLinkGoods },
                },
                [_vm._v(" 设置关联商品 ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          attrs: {
            border: "",
            stripe: "",
            "show-summary": "",
            "summary-method": _vm.hejiF,
            data: _vm.list,
          },
          on: { "sort-change": _vm.sortChange },
        },
        [
          _c("el-table-column", {
            attrs: { align: "center", type: "index", width: "50" },
          }),
          _vm._l(_vm.columns, function (item, tableIndex) {
            return _c("el-table-column", {
              key: tableIndex,
              attrs: {
                prop: item.prop,
                label: item.label,
                width: item.width,
                sortable: item.sortable ? "custom" : false,
                align: "center",
              },
            })
          }),
        ],
        2
      ),
      _c("el-pagination", {
        staticStyle: { "text-align": "center" },
        attrs: {
          "current-page": _vm.form.pageNo,
          layout: _vm.layout,
          "page-size": _vm.form.pageSize,
          total: _vm.total,
          background: "",
        },
        on: {
          "current-change": _vm.handleCurrentChange,
          "size-change": _vm.handleSizeChange,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }