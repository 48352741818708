<template>
  <div class="orderTest-container">
    <el-form
      ref="form"
      :model="form"
      inline
      label-position="right"
      label-suffix=":"
      label-width="120px"
    >
      <el-form-item label="" prop="">
        <!-- 退货方式 1现金 2转存货 3抵欠款,可多选 ,隔开 -->
        <el-select
          v-model="form.refund_type"
          multiple
          clearable
          placeholder="退货方式"
        >
          <el-option label="现金" value="1"></el-option>
          <el-option label="转存货" value="2"></el-option>
          <el-option label="抵欠款" value="3"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="" prop="staff_id">
        <StaffSelect
          ref="staffSelect"
          style="width: 180px"
          @change-staff="changeStaff"
        ></StaffSelect>
      </el-form-item>
      <el-form-item label="" prop="goods_type">
        <!-- 退货类型 6退常品 10退赠品 13退尝品 14预存退货 ,可多选 ,隔开 -->
        <el-select
          v-model="form.goods_type"
          multiple
          clearable
          placeholder="退货类型"
        >
          <el-option label="退常品" value="6"></el-option>
          <el-option label="退赠品" value="10"></el-option>
          <el-option label="退尝品" value="13"></el-option>
          <el-option label="预存退货" value="14"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="" prop="">
        <client-search
          ref="clientSearch"
          :popper-class="'selectDC'"
          @select-val="setClientKeyword"
        ></client-search>
      </el-form-item>
      <el-form-item label="" prop="">
        <goods-search
          ref="goodsSearch"
          :is-table="false"
          :popper-class="'select-idx selectDC'"
          @input-goods="setkeyword"
          @select-search-goods="setkeyword"
        ></goods-search>
      </el-form-item>
      <el-form-item label="" prop="">
        <el-select v-model="form.time_type" clearable placeholder="时间类型">
          <!-- 时间类型 1下单时间 到货时间 -->
          <el-option label="下单时间" value="1"></el-option>
          <el-option label="到货时间" value="2"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="" prop="">
        <el-date-picker
          v-model="time"
          type="daterange"
          range-separator="-"
          value-format="yyyy-MM-dd"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
        ></el-date-picker>
      </el-form-item>
      <el-form-item label="" prop="">
        <el-button type="primary" @click="handleQuery">查询</el-button>
      </el-form-item>
      <el-form-item label="" prop="">
        <el-button @click="handleExport">导出</el-button>
      </el-form-item>
      <el-form-item label="" prop="">
        <el-button type="primary" @click="handleLinkGoods">
          设置关联商品
        </el-button>
      </el-form-item>
    </el-form>
    <el-table
      v-loading="loading"
      border
      stripe
      show-summary
      :summary-method="hejiF"
      :data="list"
      @sort-change="sortChange"
    >
      <!-- 序号 -->
      <el-table-column align="center" type="index" width="50">
        <!-- <template slot='header'>
          <el-popover popper-class='custom-table-checkbox' trigger='hover'>
            <el-checkbox-group v-model='checkList'>
              <el-checkbox
                v-for='(item, index) in columns'
                :key='index'
                :label='item.label'
              ></el-checkbox>
            </el-checkbox-group>
            <el-button slot='reference' type='text'>
              <vab-remix-icon icon='settings-line' />
            </el-button>
          </el-popover>
        </template> -->
      </el-table-column>
      <el-table-column
        v-for="(item, tableIndex) in columns"
        :key="tableIndex"
        :prop="item.prop"
        :label="item.label"
        :width="item.width"
        :sortable="item.sortable ? 'custom' : false"
        align="center"
      ></el-table-column>
    </el-table>
    <el-pagination
      :current-page="form.pageNo"
      :layout="layout"
      :page-size="form.pageSize"
      :total="total"
      background
      style="text-align: center"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    ></el-pagination>
  </div>
</template>
<script>
  import { downloadFile, post_ } from '@/api/Employee'
  import StaffSelect from '@/baseComponents/staffSelect'
  import GoodsSearch from '@/baseComponents/goodsSearch'
  import ClientSearch from '@/baseComponents/clientSearch'

  export default {
    name: 'GoodsReturnSummary',
    components: { StaffSelect, GoodsSearch, ClientSearch },
    data() {
      return {
        loading: false,
        list: [],
        total: 0,
        time: [],
        form: {
          pageSize: 10,
          pageNo: 1,
          time_type: '2',
        },
        layout: 'total, sizes, prev, pager, next, jumper',
        heji: {},
        columns: [
          {
            order: 1,
            label: '退货商品(规格)',
            prop: 'goods_name',
            width: '280',
          },
          {
            order: 2,
            label: '单位换算',
            prop: 'goods_convert',
            width: '',
          },
          {
            order: 3,
            label: '关联商品',
            prop: 'rel_goods_name',
            width: '',
          },
          {
            order: 4,
            label: '退货合计数量',
            prop: 'ret_quantity',
            width: '',
          },
          {
            order: 5,
            label: '销售合计数量',
            prop: 'sale_quantity',
            width: '',
          },
          {
            order: 6,
            label: '退货率(按数量)',
            sortable: true,
            prop: 'rate',
            width: '',
          },
          {
            order: 7,
            label: '退货合计金额',
            prop: 'ret_money',
            width: '',
          },
          {
            order: 7,
            label: '销售合计金额',
            prop: 'sale_money',
            width: '',
          },
          {
            order: 7,
            label: '退货率(按金额)',
            sortable: true,
            prop: 'rate2',
            width: '',
          },
        ],
      }
    },
    computed: {},
    watch: {
      time(v) {
        if (v) {
          this.form.start_time = v[0]
          this.form.end_time = v[1]
        } else {
          this.form.start_time = ''
          this.form.end_time = ''
        }
      },
    },
    created() {},
    mounted() {
      this.fetchData()
    },
    methods: {
      handleQuery() {
        this.form.pageNo = 1
        this.fetchData()
      },
      async fetchData() {
        this.loading = true
        let form = { ...this.form }
        form.refund_type = form.refund_type && form.refund_type.join(',')
        form.goods_type = form.goods_type && form.goods_type.join(',')
        try {
          let res = await post_('/saleAdmin/return-goods/return-rate', form)
          this.list = res.data.list
          this.heji = res.data.heji

          this.total = Number(res.totalCount) || 0
          this.loading = false
        } catch (err) {
          console.log('接口调用错误', err)
          this.loading = false
        }
      },
      changeStaff(v) {
        this.form.staff_id = v
      },
      setkeyword(val) {
        this.form.keyword_goods = val
      },
      setClientKeyword(val) {
        this.form.keyword = val
      },
      hejiF(param) {
        let sums = []
        sums[4] = this.heji.ret_quantity
        sums[5] = this.heji.sale_quantity
        sums[6] = this.heji.ret_quantity
        sums[7] = this.heji.ret_money
        sums[8] = this.heji.sale_money
        sums[9] = this.heji.ret_money
        sums[0] = '合计'
        return sums
      },
      sortChange({ column, prop, order }) {
        console.log('column, prop, order', column, prop, order)
        this.form.sort = prop
        // 值 asc 小到大  和  desc  大到小]
        if (order == 'descending') {
          this.form.order = 'desc'
        } else if (order == 'ascending') {
          this.form.order = 'asc'
        } else {
          this.form.sort = ''
          this.form.order = ''
        }
        this.fetchData()
      },
      handleCurrentChange(val) {
        this.form.pageNo = val
        this.fetchData()
      },
      handleSizeChange(val) {
        this.form.pageSize = val
        this.fetchData()
      },
      handleExport() {
        let form = { ...this.form }
        form.refund_type = form.refund_type && form.refund_type.join(',')
        form.goods_type = form.goods_type && form.goods_type.join(',')
        downloadFile(
          '/saleAdmin/return-goods/return-rate-export',
          '商品退货率统计.xlsx',
          form
        )
      },
      handleLinkGoods() {
        let url = this.$router.resolve('/baseOrder/AboutGoods/retassociation')
        console.log(url)
        window.open(url.href, '_blank')
      },
    },
  }
</script>
<style lang="scss" scoped></style>
